import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

const Span = forwardRef(
  ({ children, ...props }: TextProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Text ref={ref} as="span" {...props}>
      {children}
    </Text>
  ),
);

Span.displayName = 'Span';

export { Span };
