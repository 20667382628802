import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

const B = forwardRef(
  ({ children, ...props }: TextProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Text ref={ref} as="b" fontWeight="bold" {...props}>
      {children}
    </Text>
  ),
);

B.displayName = 'B';

export { B };
