import { defineStyleConfig } from '@chakra-ui/react';

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 600,
    wordBreak: 'break-word',
  },
  defaultProps: {
    size: 'md',
  },
});
