import { useMemo } from 'react';
import type { AgGridReactProps } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-alpine.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import {
  type SystemStyleObject,
  useMultiStyleConfig,
  useStyleConfig,
  useToken,
} from '@chakra-ui/react';
import type { ThemeObject } from '@cuebox-types/chakra';

export const AG_GRID_CLASSNAME = 'ag-theme-chakra';

export const CELL_PADDING = '12px';
// AG Grid doesn't like em's so we need fixed pixel heights for some elements
const HEADER_HEIGHT = 36;
const FLOATING_FILTERS_HEIGHT = 52;
const ROW_HEIGHT = 46;
const CONTAINER_BORDER_RADIUS = 12;

// Similar to heights, we need to overwrite the full border style to get our desired look.
const BORDER_STYLE = '1px solid var(--chakra-colors-borders-base)';

const toPixels = (n: number) => `${n}px`;

// These must be passed into the AgGridReact component to work in tandem with the CSS styles below.
// There are important reasons for this, such as AG Grid dynamically measuring content and pixel heights on elements based on these values.
export const themeProps: AgGridReactProps = {
  floatingFiltersHeight: FLOATING_FILTERS_HEIGHT,
  headerHeight: HEADER_HEIGHT,
  rowHeight: ROW_HEIGHT,
};

// AG Grid uses CSS variables for many important theme attributes, so we need to set both props and vars to get the correct result.
export const agGridVariables = {
  // General styles
  '--ag-background-color': 'white',
  '--ag-border-radius': toPixels(CONTAINER_BORDER_RADIUS),
  '--ag-foreground-color': 'var(--chakra-colors-text-base)',
  // We must reference an icon web font family that is loaded via the `Fonts` component.
  '--ag-icon-font-family': 'agGridAlpine',
  '--ag-icon-size': '14px',
  '--ag-font-family': 'inherit',
  '--ag-font-size': '14px',
  '--ag-border-color': 'var(--chakra-colors-borders-base)',
  '--ag-grid-size': '8px',
  '--ag-list-item-height': '24px',
  // Header styles
  '--ag-header-height': toPixels(HEADER_HEIGHT),
  '--ag-header-column-resize-handle-display': 'block',
  '--ag-header-column-resize-handle-width': '2px',
  '--ag-header-column-resize-handle-color': 'var(--chakra-colors-gray-300)',
  // Grid styles
  '--ag-cell-horizontal-padding': CELL_PADDING,
  '--ag-row-border-color': 'var(--chakra-colors-borders-base)',
  '--ag-row-height': toPixels(ROW_HEIGHT),
  '--ag-widget-horizontal-spacing': 'var(--ag-grid-size)',
  '--ag-column-select-indent-size':
    'calc(var(--ag-icon-size) + var(--ag-widget-horizontal-spacing))',
  // Checkbox and Radio button styles
  '--ag-checkbox-unchecked-color': 'var(--chakra-colors-gray-300)',
  '--ag-checkbox-indeterminate-color': 'var(--chakra-colors-blue-500)',
  '--ag-checkbox-checked-color': 'var(--chakra-colors-blue-500)',
  // Popover styles
  '--ag-menu-min-width': '250px',
  '--ag-card-radius': 'var(--chakra-radii-md)',
  '--ag-popup-shadow': 'var(--chakra-shadows-md)',
  // Filter styles
  '--ag-input-focus-border-color': 'var(--chakra-colors-primary-darker)',
  '--ag-widget-vertical-spacing': '6px',
  // Switch styles
  '--ag-toggle-button-height': '16px',
  '--ag-toggle-button-width': '26px',
  '--ag-toggle-button-border-width': '2px',
  '--ag-toggle-button-switch-border-color': 'var(--chakra-colors-gray-300)',
  '--ag-toggle-button-off-border-color': 'var(--chakra-colors-gray-300)',
  '--ag-toggle-button-off-background-color': 'var(--chakra-colors-gray-300)',
  '--ag-toggle-button-on-border-color': 'var(--chakra-colors-blue-500)',
  '--ag-toggle-button-on-background-color': 'var(--chakra-colors-blue-500)',
};

export const useAgGridTheme = (): SystemStyleObject => {
  const tableStyles: Record<string, ThemeObject> = useMultiStyleConfig(
    'Table',
    { size: 'md' },
  );
  const inputStyles: Record<string, ThemeObject> = useMultiStyleConfig(
    'Input',
    { size: 'sm' },
  );
  const selectStyles: Record<string, ThemeObject> = useMultiStyleConfig(
    'Select',
    { size: 'sm' },
  );
  const menuStyles: Record<string, ThemeObject> = useMultiStyleConfig('Menu');
  const tooltipThemeStyles = useStyleConfig('Tooltip');
  const tagStyles = useMultiStyleConfig('Tag');

  const light300 = useToken('colors', 'light.300');

  const agGridTheme = useMemo<SystemStyleObject>(() => {
    // Top level AG Grid container div.
    const rootStyles: SystemStyleObject = {
      '.ag-root-wrapper': {
        border: 'none',
        // This is the container element which scrolls horizontally on large tables.
        // Preventing overscroll here stops the annoying accidental swipe to back/forward gestures.
        '.ag-center-cols-viewport': {
          overscrollBehaviorX: 'contain',
        },

        'input[type="checkbox"]': {
          cursor: 'pointer',
        },

        '.ag-icon-asc, .ag-icon-desc': {
          color: 'primary.darker',
        },

        // These styles apply the hover and active states to the AG Grid buttons and icons (copied from the quartz theme)
        [`.ag-header-cell-menu-button,
          .ag-header-cell-filter-button,
          .ag-panel-title-bar-button,
          .ag-header-expand-icon,
          .ag-column-group-icons,
          .ag-set-filter-group-icons,
          .ag-group-expanded .ag-icon,
          .ag-group-contracted .ag-icon,
          .ag-chart-settings-prev,
          .ag-chart-settings-next,
          .ag-group-title-bar-icon,
          .ag-column-select-header-icon,
          .ag-floating-filter-button-button,
          .ag-filter-toolpanel-expand,
          .ag-panel-title-bar-button-icon,
          .ag-chart-menu-icon,
          .ag-icon-cancel`]: {
          _hover: {
            borderRadius: '1px',
            backgroundColor: 'gray.200',
            shadow: '0 0 0 3px var(--chakra-colors-gray-200)',
          },
          _active: {
            borderRadius: '1px',
            backgroundColor: 'gray.300',
            shadow: '0 0 0 3px var(--chakra-colors-gray-300)',
          },
        },
      },
    };

    const headerStyles: SystemStyleObject = {
      // The actual header container div.
      '.ag-header': {
        bg: 'light.300',

        '.ag-header-cell, .ag-header-group-cell': {
          ...tableStyles.th,
          letterSpacing: 'normal',
          borderBottomWidth: 0,
          // The th styles have their own horizontal padding, so we have to override them
          px: CELL_PADDING,

          '.ag-header-cell-resize': {
            _after: {
              content: '""',
              position: 'absolute',
              zIndex: 1,
              display: 'var(--ag-header-column-resize-handle-display)',
              backgroundColor: 'var(--ag-header-column-resize-handle-color)',
              width: 'var(--ag-header-column-resize-handle-width)',
              height: 'calc(100% - var(--ag-grid-size) * 2)',
              top: 'var(--ag-grid-size)',
            },
          },
        },

        '.ag-header-cell.hidden': {
          '.ag-header-cell-label': {
            display: 'none',
          },
        },

        '.ag-header-expand-icon': {
          marginLeft: 'var(--ag-grid-size)',
        },

        '.ag-cell-label-container': {
          p: 0,
        },

        '.ag-floating-filter': {
          overflow: 'hidden',
        },

        '.ag-floating-filter-button': {
          // Reduce the space between the input and the button to let the input grow wider
          ml: 2,
        },
      },
    };

    // The table row container div.
    // We've overwritten a lot of focus and hover states here to match the designs.
    const rowStyles: SystemStyleObject = {
      '.ag-row': {
        /** When a row is selected with the checkbox */
        '&.ag-row-selected': {
          _before: {
            bg: 'blue.50',
          },
        },

        '.ag-grid-fade-cell-wrapper': {
          _after: {
            content: "''",
            position: 'absolute',
            display: 'block',
            top: 0,
            bottom: 0,
            right: 3,
            w: '1rem',
            zIndex: 1,
            backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), white 90%)`,
          },
        },

        _hover: {
          bg: 'light.300',

          '.ag-grid-fade-cell-wrapper': {
            _after: {
              backgroundImage: `linear-gradient(to right, rgba(255,255,255,0), ${light300} 90%)`,
            },
          },
        },
        _active: {
          opacity: 0.9,
        },

        '.ag-cell-wrapper': {
          minHeight: '100%',
        },

        '.ag-cell': {
          px: 0,
          borderWidth: 0,
        },
      },

      '.ag-cell-wrapper.ag-row-group': {
        '--ag-icon-size': '16px',
        px: 3,
        '.ag-group-value > div': {
          px: 0,
        },
        alignItems: 'center',
      },

      '.ag-pinned-left-header, .ag-pinned-left-cols-container': {
        borderRight: BORDER_STYLE,
      },

      '.ag-pinned-right-header, .ag-pinned-right-cols-container': {
        borderLeft: BORDER_STYLE,
      },

      '.ag-header-select-all': {
        '--ag-icon-size': '16px',
        paddingLeft: 1,
      },

      '.ag-selection-checkbox': {
        '--ag-icon-size': '16px',
        paddingLeft: 4,
      },
    };

    const filterStyles: SystemStyleObject = {
      // ag-menu is the wrapper around all filter and select popovers.
      // Overriding the height here prevents the menu from clipping when we have a small table height, and allows it to float over the table.
      '.ag-menu, .ag-list': {
        boxShadow: 'sm',
        height: 'fit-content',
        maxHeight: 'fit-content',
      },

      '.ag-menu-list': {
        paddingY: 2,
      },

      // ag-select-list is the dropdown menu that appears from a filter select field.
      // style it using the Chakra menu styles.
      '.ag-select-list': {
        marginTop: 1,
        ...menuStyles.list,
        zIndex: 5,
        top: 0,
      },

      '.ag-list-item, .ag-menu-option, .ag-select-agg-func-virtual-list-item': {
        ...menuStyles.item,
        fontSize: 'sm',
        cursor: 'pointer',
        height: 'auto',

        '&.ag-active-item': {
          ...menuStyles.item._focus,
        },
      },
      '.ag-select-agg-func-virtual-list-item': {
        py: 0,
      },

      '.ag-set-filter-list': {
        '.ag-virtual-list-container': {
          marginBottom: 2,
        },
      },

      // Add an -- AND -- separator between multi filters.
      '.ag-multi-filter': {
        '.ag-filter-separator': {
          borderColor: 'borders.base',
          position: 'relative',
          marginY: 2,

          _after: {
            content: '"AND"',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bg: 'white',
            p: 1,
            fontSize: 'xs',
            fontWeight: 'medium',
            letterSpacing: 'wider',
            color: 'text.lighter',
          },
        },
      },

      // ag-filter-wrapper is the start of the content area for a filter popover.
      // This is styled vaguely similar to Chakra dropdowns/menus, although not exact since the HTML structure is slightly different.
      '.ag-filter-wrapper': {
        // ag-filter-select is their multiselect/dropdown field.
        '.ag-filter-select': {
          cursor: 'pointer',
          height: 'unset',
          marginBottom: 2,
          position: 'relative',

          '.ag-picker-field-wrapper': {
            ...selectStyles.field,
            // Remove the extra right padding added normally for the select icon.
            pr: 3,
            cursor: 'pointer',
            position: 'relative',

            // The AG grid select field doesn't have a focus state so apply the Chakra focus styles here.
            "&[aria-expanded='true']": {
              ...selectStyles.field._focusVisible,
            },

            '.ag-picker-field-display': {
              m: 0,
            },

            // Use the chakra icon styles for the select icon by adding a psuedo element with a background image
            // and hiding the default AG grid icon.
            '.ag-picker-field-icon': {
              display: 'none',
            },
            _after: {
              content: '""',
              position: 'absolute',
              right: 2,
              top: '50%',
              transform: 'translateY(-50%)',
              height: 5,
              width: 5,
              // This is a direct copy of the Chakra chevron down icon from their Select component
              backgroundImage: "url('/assets/down-chevron.svg')",
            },
          },
        },
      },

      // wrapper around all filter fields and inputs.
      '.ag-input-wrapper': {
        '> input:not([type="checkbox"])': {
          ...inputStyles.field,
          backgroundColor: 'white',
          color: 'inherit',

          '&[disabled]': {
            // The default Chakra opacity of 0.4 makes it hard to read the filters
            opacity: 0.9,
            _hover: {
              borderColor: 'var(--ag-input-disabled-border-color)',
            },
          },
        },
      },

      // Side Bar
      '.ag-side-bar': {
        bg: 'light.300',
      },

      '.ag-side-buttons': {
        width: 8,
        fontWeight: 'medium',

        '.ag-side-button': {
          '--ag-icon-size': '16px',

          '&.ag-selected': {
            backgroundColor: 'white',
          },

          '.ag-side-button-button': {
            paddingY: 4,
            minHeight: 36,
          },
        },
      },

      '.ag-pivot-mode-select': {
        fontWeight: 'medium',
      },

      '.ag-column-select-header': {
        paddingY: 2.5,
        height: 'auto',
      },

      '.ag-column-select-column-label': {
        ...tableStyles.th,
        letterSpacing: 'normal',
        borderBottomWidth: 0,
        padding: 0,
      },

      '.ag-icon-grip': {
        fontFamily: 'agGridQuartz',
        '--ag-icon-size': '16px',
      },

      '.ag-column-select-list': {
        '.ag-item-highlight-top::after, .ag-item-highlight-bottom::after': {
          backgroundColor: 'primary.base',
        },
      },

      '.ag-column-select-add-group-indent': {
        marginLeft:
          'calc(var(--ag-icon-size) + var(--ag-widget-horizontal-spacing))',
      },

      '.ag-column-drop-vertical': {
        '.ag-column-drop-vertical-icon': {
          '--ag-icon-size': '16px',
          marginLeft: 0,
          marginRight: 'var(--ag-widget-horizontal-spacing)',
        },

        '.ag-column-drop-vertical-title-bar': {
          paddingTop: 2.5,
          paddingX: 3,

          '.ag-column-drop-title': {
            fontWeight: 'medium',
          },
        },

        '.ag-column-drop-vertical-list': {
          paddingBottom: 2.5,
          paddingX: 3,
        },

        '.ag-column-drop-vertical-empty-message': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'dashed 2px',
          borderRadius: 'xl',
          borderColor: 'var(--ag-border-color)',
          marginX: 3,
          marginBottom: 2.5,
          padding: 4,
        },
      },

      '.ag-column-drop-cell': {
        ...tagStyles.container,
        '--ag-icon-size': '16px',
        '--ag-icon-font-family': 'agGridQuartz',

        '.ag-column-drop-cell-drag-handle': {
          marginLeft: 0,
        },

        '.ag-column-drop-cell-text': {
          fontSize: 'xs',
          fontWeight: 'semibold',
          color: 'gray.600',
        },

        '.ag-column-drop-cell-button': {
          minWidth: 0,
        },
      },

      '.ag-checkbox-label': {
        fontWeight: 'medium',
      },

      '.ag-set-filter-tree-list': {
        height: 'calc(var(--ag-list-item-height) * 6)',
      },
    };

    const pagerStyles: SystemStyleObject = {
      '.ag-paging-panel': {
        '--ag-icon-size': '16px',
        bg: 'light.300',
      },
      '.ag-paging-number': {
        fontWeight: 'semibold',
      },
      '.ag-paging-row-summary-panel-number': {
        fontWeight: 'semibold',
      },
    };

    const tooltipStyles: SystemStyleObject = {
      '.ag-tooltip': {
        ...tooltipThemeStyles,
      },
    };

    // The theme should be set on the containing div's `sx` prop so that we can derive values from the Chakra theme in styles.
    return {
      [`&.${AG_GRID_CLASSNAME}`]: {
        ...rootStyles,
        ...headerStyles,
        ...rowStyles,
        ...filterStyles,
        ...pagerStyles,
        ...tooltipStyles,
      },
    };
  }, [
    inputStyles.field,
    light300,
    menuStyles.item,
    menuStyles.list,
    selectStyles.field,
    tableStyles.th,
    tagStyles.container,
    tooltipThemeStyles,
  ]);

  return agGridTheme;
};
