import { alertTheme } from './alert';
import { avatarTheme } from './avatar';
import { buttonTheme } from './button';
import { checkboxTheme } from './checkbox';
import { dividerTheme } from './divider';
import { formTheme } from './form-control';
import { formErrorTheme } from './form-error';
import { formLabelTheme } from './form-label';
import { headingTheme } from './heading';
import { iconTheme } from './icon';
import { iconWrapperTheme } from './icon-wrapper';
import { inputTheme } from './input';
import { menuTheme } from './menu';
import { modalTheme } from './modal';
import { numberInputTheme } from './number-input';
import { selectTheme } from './select';
import { switchTheme } from './switch';
import { tableTheme } from './table';
import { textTheme } from './text';
import { textareaTheme } from './textarea';
import { tooltipTheme } from './tooltip';

export const components = {
  Alert: alertTheme,
  Avatar: avatarTheme,
  Button: buttonTheme,
  Checkbox: checkboxTheme,
  Divider: dividerTheme,
  Form: formTheme,
  FormError: formErrorTheme,
  FormLabel: formLabelTheme,
  NumberInput: numberInputTheme,
  Heading: headingTheme,
  IconWrapper: iconWrapperTheme,
  Icon: iconTheme,
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Select: selectTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Text: textTheme,
  Textarea: textareaTheme,
  Tooltip: tooltipTheme,
};
