import {
  type ErrorInfo,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { IS_DD_ENABLED } from '@config/env';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { APP_LOGGER_NAME } from '../utils/constants';

export const CUEBOX_STAFF_MEMBER_CONTEXT_KEY = 'cueboxStaffMember';
export const ORG_ID_CONTEXT_KEY = 'orgId';
export const ORG_NAME_CONTEXT_KEY = 'orgName';

export type ErrorMeta = ErrorInfo | Record<string, unknown>;

interface UserErrorTrackingApiParams {
  isCueboxStaffMember: boolean;
}

export const useErrorTrackingApi = ({
  isCueboxStaffMember,
}: UserErrorTrackingApiParams) => {
  // Get the staff side org ID from the current user, if available.
  useEffect(() => {
    datadogLogs.setGlobalContextProperty(
      CUEBOX_STAFF_MEMBER_CONTEXT_KEY,
      isCueboxStaffMember,
    );
    datadogRum.setGlobalContextProperty(
      CUEBOX_STAFF_MEMBER_CONTEXT_KEY,
      isCueboxStaffMember,
    );
  }, [isCueboxStaffMember]);

  // Log to datadog based on: https://docs.datadoghq.com/logs/log_collection/javascript/
  // Creating custom loggers here helps us to keep logs organized and set custom severity levels to process logs in the DD dashboard.
  const logger = useMemo(
    () =>
      datadogLogs.createLogger(APP_LOGGER_NAME, {
        handler: 'http',
      }),
    [],
  );

  const trackError = useCallback(
    (err: unknown, meta?: ErrorMeta, customMessage?: string) => {
      let msg = '[unknown error]';
      if (customMessage) {
        msg = customMessage;
      } else if (err instanceof Error && err.message) {
        msg = err.message;
      }

      if (IS_DD_ENABLED) {
        if (err instanceof Error) {
          logger.error(msg, { meta }, err);
        } else {
          logger.error(msg, { meta });
        }
        datadogRum.addError(err, { meta });
      } else {
        console.error(err);
      }
    },
    [logger],
  );

  const trackWarning = useCallback(
    (err: unknown, meta?: ErrorMeta, customMessage?: string) => {
      let msg = '[unknown warning]';
      if (customMessage) {
        msg = customMessage;
      } else if (err instanceof Error && err.message) {
        msg = err.message;
      }

      if (IS_DD_ENABLED) {
        if (err instanceof Error) {
          logger.warn(msg, { meta }, err);
        } else {
          logger.warn(msg, { meta });
        }
      } else {
        console.warn(msg);
      }
    },
    [logger],
  );

  const trackInfo = useCallback(
    (message: string, meta?: ErrorMeta) => {
      if (IS_DD_ENABLED) {
        logger.info(message, { meta });
      } else {
        console.info(message);
      }
    },
    [logger],
  );

  return {
    trackError,
    trackInfo,
    trackWarning,
  };
};

export type ErrorTrackingContextApi = ReturnType<typeof useErrorTrackingApi>;

export const ErrorTrackingContext = createContext(
  {} as ErrorTrackingContextApi,
);

export const useErrorTracking = () => useContext(ErrorTrackingContext);
