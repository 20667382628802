import { modalAnatomy } from '@chakra-ui/anatomy';
import type { ResponsiveValue } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const overlayStyle = defineStyle({
  bg: 'rgba(37, 41, 46, 0.4)',
});

const dialogStyle = defineStyle({
  borderRadius: 12,
});

const headerStyle = defineStyle({
  fontSize: 'lg',
  borderBottomWidth: 1,
});

const bodyStyle = defineStyle({
  py: 5,
});

const footerStyle = defineStyle({
  borderTopWidth: 1,
  gap: 2,
  py: 3,
});

const closeButtonStyle = defineStyle({
  top: 3,
});

const baseStyle = definePartsStyle({
  body: bodyStyle,
  dialog: dialogStyle,
  footer: footerStyle,
  header: headerStyle,
  overlay: overlayStyle,
  closeButton: closeButtonStyle,
});

const sidebarWidth: ResponsiveValue<string> = {
  base: '24rem',
  md: '30rem',
  lg: '40rem',
};

const sidebarVariantStyle = definePartsStyle({
  dialog: defineStyle({
    height: '100dvh',
  }),
  header: defineStyle({
    textAlign: 'left',
  }),
  closeButton: defineStyle({
    top: 2.5,
    right: 6,
  }),
  body: defineStyle({
    background: 'backgrounds.base',
    display: 'flex',
    alignItems: 'stretch',
    padding: 0,
    '& > section': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    '& > aside': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: sidebarWidth,
      width: sidebarWidth,
      borderLeftWidth: 1,
      background: 'white',
    },
  }),
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    sidebar: sidebarVariantStyle,
  },
});
