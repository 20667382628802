import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

const I = forwardRef(
  ({ children, ...props }: TextProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Text ref={ref} as="i" fontStyle="italic" {...props}>
      {children}
    </Text>
  ),
);

I.displayName = 'I';

export { I };
