import { type ReactNode, useCallback, useMemo } from 'react';
import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { useUser } from '@clerk/nextjs';
import ErrorBoundary, { type ErrorPayload } from './components/ErrorBoundary';
import {
  ErrorTrackingContext,
  type ErrorTrackingContextApi,
  useErrorTrackingApi,
} from './hooks/useErrorTracking';

export const FallbackState = () => (
  <Center width="100%" height="100%" bg="backgrounds.base">
    <Stack
      width="fit-content"
      alignItems="center"
      direction="column"
      spacing={2}
    >
      <Heading size="sm">Something went wrong</Heading>
      <Text>{`We couldn't load this page`}</Text>
      <Box pt={1}>
        <Button onClick={() => window.location.reload()}>Try Again</Button>
      </Box>
    </Stack>
  </Center>
);

interface ErrorTrackingProviderProps {
  children: ReactNode;
}

const ErrorTrackingProvider = ({ children }: ErrorTrackingProviderProps) => {
  const { user: clerkUser } = useUser();
  const isCueboxStaffMember =
    clerkUser?.primaryEmailAddress?.emailAddress
      .toLowerCase()
      .endsWith('getcuebox.com') ?? false;

  const { trackError, trackWarning, trackInfo } = useErrorTrackingApi({
    isCueboxStaffMember,
  });

  const handleBoundaryError = useCallback(
    ({ error, info }: ErrorPayload) => {
      trackError(error, info);
    },
    [trackError],
  );

  const errorContext = useMemo<ErrorTrackingContextApi>(
    () => ({
      trackError,
      trackInfo,
      trackWarning,
    }),
    [trackError, trackInfo, trackWarning],
  );

  return (
    <ErrorTrackingContext.Provider value={errorContext}>
      <ErrorBoundary fallback={FallbackState} onError={handleBoundaryError}>
        {children}
      </ErrorBoundary>
    </ErrorTrackingContext.Provider>
  );
};

export default ErrorTrackingProvider;
